@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap');

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    font-family: 'Quicksand', sans-serif;
  }
  
  .table-row:hover {
    background-color: #f5f5f5;
    font-size: 12px;
  }
  