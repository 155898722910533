
.imagem-nao-encontrada {
  width: 50%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.carousel-item img {
    max-height: 50%; 
    height: 50%;
    width: auto; 
    margin: 0 auto; 
    display: block;
  }
  
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 50%; 
    padding: 10px;
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    top: 50%;
    transform: translateY(-50%); 
    width: auto; 
    background-color: transparent;
  }
  
  .carousel-control-prev-icon::before,
  .carousel-control-next-icon::before {
    color: white; 
    font-size: 30px; 
  }
  